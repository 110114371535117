* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.layput .RaLayout-root-12 {
  /* width: 100vw !important; */
  /* overflow-x: hidden !important; */
}

.RaLayout-appFrame-13{
 /*  width: 100vw !important; */
  /* overflow-x: hidden !important; */
}

.RaLayout-contentWithSidebar-14 {
  /* width: 100vw !important; */
  /* max-width: 100vw; */
  /* overflow-x: hidden !important; */
  /* border: 5px solid red; */
  /* overflow-x: hidden; */
}

.RaLayout-content-15 {
  /* overflow-x: scroll; */
}

.MuiTable-root {
  flex: 1;
  overflow-x: hidden !important;
}

.rdg-editor-container { z-index: 10051 !important; }

.modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    box-shadow: 4px 4px grey;
    background-color: white;
    z-index: 100000000000;
}

.btn {
    background-color: teal;
}

.canvas-container {
    
}

::-webkit-scrollbar {
    width: 10px;
    height: 15px;
   }
   ::-webkit-scrollbar-thumb {
    background: linear-gradient(13deg, crimson 14%,crimson 64%);
    border-radius: 7px;
   }
   ::-webkit-scrollbar-thumb:hover{
    background: linear-gradient(13deg, crimson 14%,crimson 64%);
    /* background: linear-gradient(13deg, #c7ceff 14%,#f9d4ff 64%); */
   }
   ::-webkit-scrollbar-track{
    background: linear-gradient(13deg, transparent  14%, transparent  0%);
    border-radius: 7px;
   }

.teste-write-mode::after {
    writing-mode: sideways-lr;
    -ms-writing-mode: sideways-lr;
    text-orientation: sideways;
}

@keyframes btnImprimirAnimation {
    0% {
      color: #c3454a;
    }
    100% {
      color: green;
    }
  }
  
  .btnImprimir {
    animation: btnImprimirAnimation 2s ease-in 5s infinite alternate;
  }